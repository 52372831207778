import React from "react";
import { Fade } from 'react-reveal';
import ColorNavbar from "../../components/Navbar/ColorNavbar.jsx";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledCarousel,
  Row,
  Col
} from "reactstrap";

const carouselItems = [
  {
    src: require("../../assets/img/pcmain.JPG"),
    altText: "Inside my machine",
    caption: ""
  },
  {
    src: require("../../assets/img/pc2.JPG"),
    altText: "Inside my machine",
    caption: ""
  },
  {
    src: require("../../assets/img/pc3.JPG"),
    altText: "Inside my machine",
    caption: ""
  }
]

const carouselItems2 = [
  {
    src: require("../../assets/img/oldPC2.jpg"),
    altText: "Inside my machine",
    caption: ""
  },
  {
    src: require("../../assets/img/oldPC1.jpg"),
    altText: "Inside my machine",
    caption: ""
  }
]

const carouselItems3 = [
  {
    src: require("../../assets/img/chiefPC.jpg"),
    altText: "Inside my machine",
    caption: ""
  }
]

export default class Hobbies extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          modalPC: false,
          modalSHC: false,
      };
      this.togglemodalPC = this.togglemodalPC.bind(this);
      this.togglemodalSHC = this.togglemodalSHC.bind(this);
  }
  togglemodalPC = () => {
    this.setState({
      modalPC: !this.state.modalPC
    });
  };

  togglemodalSHC = () => {
    this.setState({
      modalSHC: !this.state.modalSHC
    });
  };

  render() {
    return (
      <>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous"/>
      <Modal isOpen={this.state.modalPC} toggle={this.togglemodalPC}>
        <div className="modal-header justify-content-center">
          <h2 className="modal-title">
            PC Building
          </h2>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={this.togglemodalPC}
          >
            <i style={{color: "black"}} className="material-icons">close</i>
          </button>
        </div>
        <ModalBody>
          <h4 style={{color: "#515151", textAlign: "center"}} className="title">Custom Built Computers</h4>
          <p style={{color: "#515151"}}>
            For the past six years, I have been building PCs in my free time for
            myself and anyone who asks me to. The builds have varied from lower end
            web browsing machines to high-end custom water cooled gaming machines. I take
            immense pride in my ability to hand pick quality parts and assmeble them
            so that the user never has to worry about the hardware.
          </p>
        </ModalBody>
        <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
        <ModalBody>
          <UncontrolledCarousel items={carouselItems3} />
        </ModalBody>
        <ModalBody>
          <h4 style={{color: "#515151", textAlign: "center"}}><b>Halo PC and Keyboard Build</b></h4>
            <p style={{color: "#515151", textAlign: "center"}}>
              i7-8700k • GTX 2080ti • 16GB RAM • 1TB SSD
            </p>
            <p style={{color: "#515151"}}>
              This was a PC and Keyboard I built to be given away to a raffle winner but unfortunately it was
              cancelled due to the COVID-19 pandemic. This PC was the same internally as my personal build but had
              a blue custom water cooling loop inside and the case was signed by Steve Downs (Master Chief) and Jen Taylor (Cortana) from
              the Halo franchise. 
            </p>
        </ModalBody>
        <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
        <ModalBody>
          <UncontrolledCarousel items={carouselItems2} />
        </ModalBody>
        <ModalBody>
          <h4 style={{color: "#515151", textAlign: "center"}}><b>My Open Air Build</b></h4>
            <p style={{color: "#515151", textAlign: "center"}}>
              i7-8700k • GTX 2080ti • 16GB RAM • 1TB SSD
            </p>
            <p style={{color: "#515151"}}>
              This was my personal build and one that has taken many forms throughout
              the years. This previous iteration served as a gaming, web development
              machine (as it dual boots Windows 10 and Pop!OS), and was a show piece for my
              PC building ability.
            </p>
        </ModalBody>
        <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
        <ModalBody>
          <UncontrolledCarousel items={carouselItems} />
        </ModalBody>
        <ModalBody>
          <h4 style={{color: "#515151", textAlign: "center"}}><b>My Original Build</b></h4>
            <p style={{color: "#515151", textAlign: "center"}}>
              i7-8700k • GTX 2080ti • 16GB RAM • 1TB SSD
            </p>
            <p style={{color: "#515151"}}>
              This was my personal build and one that has taken many forms throughout
              the years. This previous iteration served as a gaming, web development
              machine (as it dual boots Windows 10 and Pop!OS), and was a show piece for my
              PC building ability.
            </p>
        </ModalBody>
        <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
        <ModalFooter style={{paddingTop: 20}} >
            <Button color="info" onClick={this.togglemodalPC}>
                Close
            </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={this.state.modalSHC} toggle={this.togglemodalSHC}>
        <div className="modal-header justify-content-center">
          <h2 className="modal-title">
            Development Stuff
          </h2>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={this.togglemodalSHC}
          >
            <i style={{color: "black"}} className="material-icons">close</i>
          </button>
        </div>
        <ModalBody>
          <h4 style={{color: "#515151", textAlign: "center"}} className="title">Smart Health Card Scanner</h4>
          <p style={{color: "#515151"}}>
            During my time working on the Smart Health Card (SHC) initiative at Kaiser, I noticed a lack of open source,
            easy to use SHC scanners/verifiers. This is an issue because users have the right to know what is in their QR code
            and should not have to rely on a third party app to scan it and hope it tells the truth. With that, I built an open source, client
            side scanner that allows you to scan or upload your QR code, see the data inside, and tell you whether or not you recieved it from
            a trusted party. In addition, it also allows you to download a PDF version that can be taken anywhere.
          </p>
        </ModalBody>
        <br />
        <ModalBody>
          <a href={"https://www.shcverifier.com/"}>That website is located here</a>
        </ModalBody>
        <ModalFooter style={{paddingTop: 20}} >
            <Button color="info" onClick={this.togglemodalPC}>
                Close
            </Button>
        </ModalFooter>
      </Modal>
      <div className="wrapper" ref="wrapper">
        <Fade center>
          <div id="stuffILikeToDo">
            <Container>
              <Card style={{backgroundColor:"#EEEEEE"}} className="text-center">
              <h1 style={{color: "#515151"}} className="title">Stuff I Like To Do</h1>
              </Card>
            </Container>
          </div>
        </Fade>
        <Fade center>
          <div>
            <Container>
              <Card style={{marginBottom: "20px"}} className="card-blog card-background card-grande">
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("../../assets/img/shcPreview.png") + ")"
                  }}
                />
                <CardBody>
                  <CardTitle style={{paddingTop: 200}} tag="h2">Smart Health Card Verifier</CardTitle>
                    <h4 className="card-description">
                      I built an open source Smart Health Card verification application! <br />
                    <br />
                    </h4>
                  <Button color="primary" onClick={this.togglemodalSHC}>
                    Read about it!
                  </Button>
                </CardBody>
              </Card>
            </Container>
          </div>
        </Fade>
        <Fade center>
          <div>
            <Container>
              <Card style={{marginBottom: "20px"}} className="card-blog card-background card-grande">
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("../../assets/img/pcmain.JPG") + ")"
                  }}
                />
                <CardBody>
                  <CardTitle style={{paddingTop: 200}} tag="h2">PC Building</CardTitle>
                    <h4 className="card-description">
                      I build custom PCs for any situation and budget! <br />
                    <br />
                    </h4>
                  <Button color="primary" onClick={this.togglemodalPC}>
                    See some builds!
                  </Button>
                </CardBody>
              </Card>
            </Container>
          </div>
        </Fade>
      </div>
      </>
    );
  }
}
