import React, { Component } from 'react';

// core componentss
import ColorNavbar from "../components/Navbar/ColorNavbar.jsx";
import Footer from "../components/Footers/Footer.jsx";
import ProfilePage from "./pages/ProfilePage.jsx";
// import DemoFooter from "components/Footers/DemoFooter.jsx";

// Sections for this page
// (we've divided this page into multiple react components to make it a bit more readable)
// import ProfilePage from "../views/pages/ProfilePage.jsx"
// import Resume from "../views/pages/Resume.jsx"

export default class Index extends Component {
  componentDidMount() {
    document.body.classList.add("index-page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }
  componentWillUnmount() {
    document.body.classList.remove("index-page");
  }
  render() {
    return (
      <>
        <div>
          <ColorNavbar />
          <ProfilePage />
          <Footer />
        </div>
      </>
    );
  }
}
