import React from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import { Fade } from 'react-reveal';
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

export default class ColorNavbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      navbarColor: "navbar-transparent",
      headerTextColor: "#EEEEEE",
      dropdownTextColor: "#EEEEEE",
      dropdownColor: "#EEEEEE",
      linesColor: "#515151"
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);
     let headroom = new Headroom(document.getElementById("nav"));
    // initialise
     headroom.init();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavbarColor);
  }
  toggleNavbar = () => {
    this.setState({
    collapsed: !this.state.collapsed
    });
  }

  closeNavbar = () => {
      if (this.state.collapsed !== true) {
      this.toggleNavbar();
      }
  }

  scrollPage = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView({behavior: "smooth"});
    }
  };

  changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 79 ||
      document.body.scrollTop > 79
    ) {
      this.setState({
        navbarColor: "bg-white",
        headerTextColor: "#515151",
        linesColor: "#515151",
        dropdownTextColor: "#EEEEEE",
        dropdownColor: "#023787"
      });
    } else if (
      document.documentElement.scrollTop < 80 ||
      document.body.scrollTop < 80
    ) {
      this.setState({
        navbarColor: "navbar-transparent",
        headerTextColor: "#EEEEEE",
        linesColor: "#EEEEEE",
        dropdownTextColor: "#EEEEEE",
        dropdownColor: "#EEEEEE"
      });
    }
  };

  render() {
    return (
      <>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet" />
    <Navbar className={"fixed-top " + this.state.navbarColor} expand="lg" id="nav">
          <Container>
            <div className="navbar-translate">
              <NavbarBrand style={{color: this.state.headerTextColor}} onClick={e => this.scrollPage(e, "top")} tag={Link}>
                <span>DAM •</span> Daniel Aaron Maguire
              </NavbarBrand>
              <button className="navbar-toggler" onClick={this.toggleNavbar}>
                <span style={{color: this.state.headerTextColor}} className="navbar-toggler-bar bar1" />
                <span style={{color: this.state.headerTextColor}} className="navbar-toggler-bar bar1" />
                <span style={{color: this.state.headerTextColor}} className="navbar-toggler-bar bar1" />
              </button>
            </div>
            <Fade center>
              <Collapse isOpen={!this.state.collapsed} navbar style={{backgroundColor: "#515151"}}>
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <a style={{color: this.state.dropdownTextColor}}>
                        • DAM •
                      </a>
                    </Col>
                    <Col className="collapse-close text-right" xs="6">
                      <button
                        className="navbar-toggler"
                        id="navigation"
                        onClick={e => this.closeNavbar()}
                      >
                      <i style={{color: this.state.dropdownTextColor}} className="material-icons">close</i>
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink
                      onClick={e => {this.scrollPage(e, "top"); this.closeNavbar()}}
                      style={{color: this.state.dropdownTextColor}}
                      tag={Link}
                      >
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={e => {this.scrollPage(e, "whatIDoNow"); this.closeNavbar()}}
                      style={{color: this.state.dropdownTextColor}}
                      tag={Link}
                      >
                      What I Do Now
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={e => {this.scrollPage(e, "whatIUsedToDo"); this.closeNavbar()}}
                      style={{color: this.state.dropdownTextColor}}
                      tag={Link}
                      >
                      What I Used To Do
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={e => {this.scrollPage(e, "stuffILikeToDo"); this.closeNavbar()}}
                      style={{color: this.state.dropdownTextColor}}
                      tag={Link}
                      >
                      Stuff I Like To Do
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Fade>
          </Container>
        </Navbar>
      </>
    );
  }
}
