import React from "react";
import { Fade } from 'react-reveal';
import { Image } from 'react-bootstrap';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledCarousel,
  Row,
  Col
} from "reactstrap";

export default class Resume extends React.Component {
  constructor(props) {
      super(props);
      let windowScrollTop;
      if (window.innerWidth >= 768) {
        windowScrollTop = window.pageYOffset / 3;
      } else {
        windowScrollTop = 0;
      }
      this.state = {
          transform: "translate3d(0," + windowScrollTop + "px,0)",
          modalRP: false,
          modalBC: false,
          modalBC2: false,
          modalAZ: false,
      };
      this.togglemodalRP = this.togglemodalRP.bind(this);
      this.togglemodalBC = this.togglemodalBC.bind(this);
      this.togglemodalBC2 = this.togglemodalBC2.bind(this);
      this.togglemodalAZ = this.togglemodalAZ.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("blog-posts");
    if (window.innerWidth >= 768) {
      var windowScrollTop = window.pageYOffset / 3;
      this.setState({
        transform: "translate3d(0," + windowScrollTop + "px,0)"
      });
      window.addEventListener("scroll", this.resetTransform);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("profile-page");
    if (window.innerWidth >= 768) {
      window.removeEventListener("scroll", this.resetTransform);
    }
  }
  resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)"
    });
  };
  togglemodalRP = () => {
    this.setState({
      modalRP: !this.state.modalRP
    });
  };

  togglemodalBC = () => {
    this.setState({
      modalBC: !this.state.modalBC
    });
  };

  togglemodalBC2 = () => {
    this.setState({
      modalBC2: !this.state.modalBC2
    });
  };

  togglemodalAZ = () => {
    this.setState({
      modalAZ: !this.state.modalAZ
    });
  };

  render() {
    return (
      <>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous"/>
        <Modal isOpen={this.state.modalRP} toggle={this.togglemodalRP} >
          <div className="modal-header justify-content-center">
            <h2 className="modal-title">
              Kaiser Permanente
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={this.togglemodalRP}
            >
              <i style={{color: "black"}} className="material-icons">close</i>
            </button>
          </div>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}} className="title">Programming Analysis Associate</h4>
            <p style={{color: "#515151"}}>
              This was a two year rotation program in which I had the opprotunity
              to explore four areas within Kaiser IT. Each six month rotation
              gave me the opprotunity to interact and assist the team I was on
              with their projects and deliverables.
            </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Rotation One</b> <br /> Medical Imaging Informatics</h4>
              <p style={{color: "#515151", textAlign: "center"}}>
                PHP • HTML • CSS • C3.js  • SQL
              </p>
              <p style={{color: "#515151"}}>
                During this rotation I was tasked with recreating an existing dashbord
                with more current technology. This dashboard visualized different scan
                types, location based SLAs, load balancing, and more information that was
                located in the nearly 5 petabyte medical imaging database.
              </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Rotation Two</b> <br /> Facilites Services <br /> Application Development</h4>
              <p style={{color: "#515151", textAlign: "center"}}>
                JS • HTML • SQL • Sharepoint
              </p>
              <p style={{color: "#515151"}}>
                This rotation focused on refactoring the application team's website as well
                as updating queries associated with the office booking system. The code
                refactoring was done to make future upgrades of the site easier to manage.
                Queries for the office booking system were out of date and did not contain
                new offices so they were updated to incorporate all available offices.
              </p>
              <p style={{color: "#515151"}}>
                I also created an oboarding form using Sharepoint for managers to quickly
                grant new employees access to critical systems. This tool reduced
                new employeess down time due to their inability to access systems from
                one week to one day.
              </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Rotation Three</b> <br /> Technology Risk Office <br /> Business Intelligence</h4>
              <p style={{color: "#515151", textAlign: "center"}}>
                Python • Salesforce • Tableau
              </p>
              <p style={{color: "#515151"}}>
                The team on my third rotation had me create a tool in Python for pulling results
                from Salesforce instead of doing it manually from the site. This tool
                pulled data every two hours in order to have close to real time data on
                the team's Tableau dashboards.
              </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Rotation Four</b> <br /> Strategic Platforms <br /> Blockchain</h4>
              <p style={{color: "#515151", textAlign: "center"}}>
                Ethereum • Solidity • React • Meteor
              </p>
              <p style={{color: "#515151"}}>
                My final rotation had two main goals: research blockchain tecnologies
                and provide a proof-of-concept to demonstrate the value to the organization.
                To achieve this, our team settled on Ethereum as our blockchain of choice and
                Meteor as our framework for web applications. The proof-of-concept showed how a
                patient can control access to thier medical record with a smart contract.
              </p>
          </ModalBody>
          <ModalFooter style={{paddingTop: 20}} >
              <Button color="info" onClick={this.togglemodalRP}>
                  Close
              </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalBC} toggle={this.togglemodalBC}>
          <div className="modal-header justify-content-center">
            <h2 className="modal-title">
              Kaiser Permanente
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={this.togglemodalBC}
            >
              <i style={{color: "black"}} className="material-icons">close</i>
            </button>
          </div>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Strategic Platforms</b> <br /> Associate IT Engineer Applications</h4>
            <p style={{color: "#515151"}}>
              I worked on evaluating blockchain as a technology
              and developing web applications to support our use cases. I was the
              primary developer on the team but was assisted by rotational associates
              who take on smaller portions of the development work.
              <br />
              Listed below are the the technologies I work on
            </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Ethereum and Geth</b></h4>
            <p style={{color: "#515151"}}>
              Ethereum has been the primary blockchain I have worked on as it offers an open-source
              client, a native coin, a Turing complete smart contract language, and the largest
              comminity of developers of any blockchian.
            </p>
          </ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Solidity</b></h4>
            <img style={{width: "75%"}} alt="..." className="img-center img-fluid" src={require("../../assets/img/solidityGif.gif")}/>
            <p style={{color: "#515151"}}>
              To assist in our use cases, I develop smart contracts using the
              Solidity programming language. Part of my certification as an
              Ethereum developer was utilizing Solidity to create robust and
              secure smart contracts to satisfy numerous requirements.
            </p>
          </ModalBody>
          <ModalFooter style={{paddingTop: 20}} >
              <Button color="info" onClick={this.togglemodalBC}>
                  Close
              </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalBC2} toggle={this.togglemodalBC2}>
          <div className="modal-header justify-content-center">
            <h2 className="modal-title">
              Kaiser Permanente
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={this.togglemodalBC2}
            >
              <i style={{color: "black"}} className="material-icons">close</i>
            </button>
          </div>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Strategic Platforms</b> <br />IT Engineer Applications</h4>
            <p style={{color: "#515151"}}>
              Currently I am evaluating the application of Decentralized Identites within the
              healthcare ecosystem as well as how passwordless accounts could work. This would include how patients would use them as well
              as employees. In this evalutaion period I have been researching FIDO2/WebAuthn as well as the TOIP stack. In addition, I have also been
              working on the Smart Health Card implementaion at Kaiser. I built the initial prototype that showed off to generate and read QR codes
              as verifiable credentials.
              <br />
              <br />
              Listed below are the the technologies I work on
            </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Smart Health Cards</b></h4>
            <p style={{color: "#515151"}}>
              The Smart Health Card prototype was built in April of 2021 according the standard proposed by The Commons Project.
              This prototype was built using React and open source NPM libraries in order to create a client only solution. This way users
              would not have to worry about their information being kept anywhere but in their browser. The application was able to create the QR codes
              as well as parse them and tell you if they were signed by a valid party or not.
            </p>
          </ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Decentralized Identity/Verifiable Credentials</b></h4>
            <p style={{color: "#515151"}}>
              A demo appliation was built that utilized Evernym's Connect.me application to generate Decentralized Identities for users,
              issue them verifiable credentials, and a site where they could use the verifiable credential to prove they were who they claim to be.
              This was done using React and the Evernym SDK and proved the viability of the technology.
            </p>
          </ModalBody>
          <ModalFooter style={{paddingTop: 20}} >
              <Button color="info" onClick={this.togglemodalBC2}>
                  Close
              </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalAZ} toggle={this.togglemodalAZ}>
          <div className="modal-header justify-content-center">
            <h2 className="modal-title">
              University of Arizona Bookstore
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={this.togglemodalAZ}
            >
              <i style={{color: "black"}} className="material-icons">close</i>
            </button>
          </div>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}} className="title">Souvenirs Department</h4>
              <img
                style={{maxWidth: "40%"}}
                className="img-fluid img-center"
                src={require("../../assets/img/souv.jpg")}
                alt=""
              />
            <br />
            <p style={{color: "#515151"}}>
              The souvenirs department at the Univeristy of Arizona Bookstore is
              responsible for selling merchandise at all sporting events. They
              manage multiple booths and tents at any giving time and have about
              30 employees working at any given time.
            </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}} className="title"><b>Souvenirs Department Lead</b></h4>
            <p style={{color: "#515151"}}>
              After one year as a retail associate at the Univeristy of Arizona Bookstore,
              I was promoted to the lead of the souviners department. In this role I managed
              the departments inventory, created and approved schedules, oversaw the day to day
              activities of 20 student workers, maintained sales reports, and was an active part
              of the sales team.
            </p>
          </ModalBody>
          <ModalBody><hr color="#515151" style={{width: "100%"}}/></ModalBody>
          <ModalBody>
            <h4 style={{color: "#515151", textAlign: "center"}}><b>Souvenirs Department Retail Associate</b></h4>
              <p style={{color: "#515151"}}>
                During my time as a retail associate I was tasked with maintaining inventory
                of products, organizing products for merchandise booths at sports events,
                and working a register.
              </p>
          </ModalBody>
          <ModalFooter style={{paddingTop: 20}} >
              <Button color="info" onClick={this.togglemodalAZ}>
                  Close
              </Button>
          </ModalFooter>
        </Modal>
        <div className="wrapper" ref="wrapper" id="top">
          <div className="page-header page-header-small header-filter">
            <div
              className="page-header-image"
              style={{
                backgroundImage: "url(" + require("../../assets/img/ProfileBackground.png") + ")",
                transform: this.state.transform
              }}
            />
            <div style={{display: "block", marginLeft: "auto", marginRight: "auto"}}>
              <Row>
                <Col>
                  <Fade center>
                    <Image
                      style={{maxWidth:"20%"}}
                      className="img-fluid img-center"
                      src={require("../../assets/img/profile.jpg")} roundedCircle
                      />
                    </Fade>
                  </Col>
                </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6" id="That's true">
                  <Fade center>
                    <h1 className="title">
                      Daniel Aaron Maguire
                    </h1>
                    <h5 ><b>Developer and Blockchain Enthusiast</b></h5>
                  </Fade>
                </Col>
              </Row>
              <Fade center>
                <Row style={{textAlign: "center"}}>
                  <Col>
                    <Button href="https://www.linkedin.com/in/danielamaguire/" target="_blank" className="btn-icon btn-simple" color="linkedin">
                      <i className="fab fa-linkedin" />
                    </Button>
                    <Button href="https://github.com/damaguire" target="_blank" className="btn-icon btn-simple" color="instagram">
                      <i className="fab fa-github" />
                    </Button>
                  </Col>
                </Row>
              </Fade>
            </div>
          </div>
          <div className="main main-raised">
            <Container className="align-items-center">
              <Card style={{paddingBottom: 20, backgroundColor:"#EEEEEE"}} className="text-center" id="about">
                <CardHeader>
                  <h1 className="title" style={{color: "#515151"}}>About</h1>
                </CardHeader>
                <hr color="#515151" style={{width: "80%", margin: "15px auto"}}></hr>
                <CardBody>
                  <p style={{padding: 50, color: "#515151"}} className="description">
                    Hi there! I'm Dan and welcome to my site! I made this as a fun little project
                    and to be a bit more entertaining than just going through my LinkedIn.
                  </p>
                </CardBody>
              </Card>
            </Container>
          </div>
          <Fade center>
            <div>
              <Container>
                <Card style={{backgroundColor:"#EEEEEE"}} className="text-center" id="whatIDoNow">
                <h1 style={{color: "#515151"}} className="title">What I Do Now</h1>
                </Card>
              </Container>
            </div>
          </Fade>
          <Fade center>
            <div>
              <Container>
                <Card className="card-blog card-background card-grande">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/img/kaiserBuilding2.jpg") + ")"
                    }}
                  />
                  <CardBody>
                    <CardTitle style={{paddingTop: 200}} tag="h2">Kaiser Permanente</CardTitle>
                    <h4 className="card-description"><b>
                      IT Applications Engineer <br />
                      2020 - Current
                    </b></h4>
                  <Button color="info" onClick={this.togglemodalBC2}>
                        More info
                    </Button>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </Fade>
          <Fade center>
            <div id="whatIUsedToDo">
              <Container>
                <Card style={{backgroundColor:"#EEEEEE"}} className="text-center">
                <h1 style={{color: "#515151"}} className="title">What Used To Do</h1>
                </Card>
              </Container>
            </div>
          </Fade>
          <Fade center>
            <div>
              <Container>
                <Card className="card-blog card-background card-grande">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/img/kaiserBuilding.jpg") + ")"
                    }}
                  />
                  <CardBody>
                    <CardTitle style={{paddingTop: 200}} tag="h2">Kaiser Permanente</CardTitle>
                    <h4 className="card-description"><b>
                      Associate IT Applications Engineer <br />
                      2018 - 2020
                    </b></h4>
                    <Button color="info" onClick={this.togglemodalBC}>
                        More info
                    </Button>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </Fade>
          <Fade center>
            <div>
              <Container>
                <Card className="card-blog card-background card-grande">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/img/kaiserBuilding.jpg") + ")"
                    }}
                  />
                  <CardBody>
                    <CardTitle style={{paddingTop: 200}} tag="h2">Kaiser Permanente</CardTitle>
                    <h4 className="card-description">
                      Programming Analysis Associate <br />
                      2016 - 2018
                    </h4>
                    <Button color="info" onClick={this.togglemodalRP}>
                      More info
                    </Button>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </Fade>
          <Fade center>
            <div>
              <Container>
                <Card className="card-blog card-background card-grande">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/img/uofa.jpg") + ")"
                    }}
                  />
                  <CardBody>
                    <CardTitle style={{paddingTop: 200}} tag="h2">University of Arizona</CardTitle>
                    <h4 className="card-description">
                      Souvenirs Department <br />
                    2013 - 2015
                    </h4>
                    <Button color="danger" onClick={this.togglemodalAZ}>
                      More info
                    </Button>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </Fade>
        </div>
      </>
    );
  }
}
