import React from "react";
// reactstrap components
import {
  NavLink,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container
} from "reactstrap";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalContact: false,
    };
    this.togglemodalContact = this.togglemodalContact.bind(this);
  }
  scrollPage = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView({behavior: "smooth"});
    }
  };
  togglemodalContact = () => {
    this.setState({
      modalContact: !this.state.modalContact
    });
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.modalContact} toggle={this.togglemodalContact}>
          <div className="modal-header justify-content-center">
            <h2 className="modal-title">
              Contact Information
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={this.togglemodalContact}
            >
              <i style={{color: "black"}} className="material-icons">close</i>
            </button>
          </div>
          <ModalBody>
            <p style={{color: "#515151", textAlign: "center"}}>
              All my social media pages are located at the top of my page but here
              they are again!
            </p>
            <Row style={{textAlign: "center"}}>
              <Col>
                <Button href="https://twitter.com/itsapelican" target="_blank" className="btn-icon btn-simple" color="twitter" >
                  <i className="fab fa-twitter" />
                </Button>
                <Button href="https://www.linkedin.com/in/danielamaguire/" target="_blank" className="btn-icon btn-simple" color="linkedin">
                  <i className="fab fa-linkedin" />
                </Button>
                <Button href="https://www.facebook.com/danielamaguire" target="_blank" className="btn-icon btn-simple" color="facebook">
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button href="https://www.instagram.com/maguired7/" target="_blank" className="btn-icon btn-simple" color="dribbble">
                  <i className="fab fa-instagram" />
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <footer className="footer footer-simple">
          <Container>
            <nav>
              <ul>
                <li>
                  <NavLink
                    onClick={e => this.scrollPage(e, "top")}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={e => this.scrollPage(e, "about")}
                  >
                   About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={e => this.scrollPage(e, "whatIDoNow")}
                  >
                    What I Do Now
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={e => this.scrollPage(e, "whatIUsedToDo")}
                  >
                     What I Used To Do
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={e => this.scrollPage(e, "stuffILikeToDo")}
                  >
                    Stuff I Like To Do
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className="copyright">
              <Button onClick={this.togglemodalContact}>
                  Reach out!
              </Button>
            </div>
          </Container>
        </footer>
      </>
    );
  }
}
