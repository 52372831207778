import React from "react";
import Hobbies from './Hobbies.jsx';
import Resume from './Resume.jsx';
// reactstrap components

export default class ProfilePageTest extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous"/>
          <Resume />
          <Hobbies />
      </>
    );
  }
}
