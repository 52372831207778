import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

// styles
import "./assets/scss/blk-design-system-pro-react.scss?v1.0.0";

// presentation pages
//import ProfilePage from "./views/pages/ProfilePage.jsx";
import Index from "./views/Index.jsx"

ReactDOM.render(
  <Router>
      <Route exact path="/" component={Index} />
  </Router>,
  document.getElementById("root")
);
